
import {
    defineComponent, reactive, computed, onBeforeMount, nextTick,
} from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import coreStore from '@/store/CoreStore';
import Screen from '@/components/layout/Screen.vue';
import Transaction from '@/domain/Transaction';
import { ItemTableData } from '@/domain/ItemTableData';
import RouteConfigService from '@/services/RouteConfigService';
import SupplierShipmentService from '@/services/SupplierShipmentService';
import Shipment from '@/domain/Shipment';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import TransactionFooter from '@/components/TransactionFooter.vue';
import TransactionQuantityNumberInput from '@/components/TransactionQuantityNumberInput.vue';
import useSupplierShipping from '@/composable/useSupplierShipping';
import TransactionState from '@/interfaces/TransactionState';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import { ItemQtyKey, MaxTrailerProps, useMaxTrailerCompute } from '@/composable/useMaxTrailerCompute';
import TrailerType from '@/domain/TrailerType';
import Item from '@/domain/Item';
import { Formatter, TransactionStatus } from '@/domain/TransactionStatus';
import NonWorkDayService from '@/services/NonWorkDayService';
import router from '@/router';
import EntityType from '@/domain/enums/EntityTypes';
import MasterDataSupplierShippingHead from '@/modules/master-data/views/transactions/supplier-shipping/components/MasterDataSupplierShippingHead.vue';
import { useNotification } from '@/composable/useNotifications';
import BButton from '@/components/bootstrap-library/BButton.vue';
import { ItemType } from '@/domain/enums/ItemType';
import ItemService from '@/services/ItemService';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import Permissions from '@/services/permissions/Permissions';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import ReservationService from '@/services/ReservationService';
import { formatDateUI } from '@/functions/date';
import AllOrOnRouteItemSelector from '@/components/AllOrOnRouteItemSelector.vue';

interface State extends TransactionState {
    shipment: Shipment;
    transaction: Transaction;
    loading: boolean;
    saving: boolean;
    shipping: boolean;
    itemList: Array<ItemTableData>;
    useShippingSpecificValidation: boolean;
    showItemSearch: boolean;
    showNotes: boolean;
    notes: string;
    wasNewShipment: boolean;
    showTags: boolean;
    isLocationChanged: boolean;
}

type TagTableData = {
    item?: any;
    barcode?: string;
    productionPlant?: string;
    productionDate?: Date;
}

export default defineComponent({
    name: 'supplier-shipping-transaction-screen',
    components: {
        Thumbnail,
        MasterDataSupplierShippingHead,
        Screen,
        TransactionFooter,
        TransactionQuantityNumberInput,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BTable,
        BButton,
        BSpinner,
        AllOrOnRouteItemSelector,
    },
    props: {
        modelValue: {
            type: Transaction,
            default: () => new Transaction(),
        },
    },
    setup(props) {
        const { userLocation } = coreStore.getInstance().profileStore;
        const routeConfigService = new RouteConfigService();
        const shipmentService = new SupplierShipmentService();
        const nonWorkDayService = new NonWorkDayService(coreStore.getInstance().timeSetStore.allTimeSets);
        const itemService = new ItemService();
        const reservationService = new ReservationService();
        const notification = useNotification();

        const state = reactive<State>({
            shipment: new Shipment(),
            transaction: new Transaction(),
            originalShipmentStringified: '',
            loading: false,
            saving: false,
            shipping: false,
            itemList: [],
            useShippingSpecificValidation: false,
            showItemSearch: false,
            showNotes: false,
            notes: '',
            wasNewShipment: false,
            showTags: false,
            isLocationChanged: false,
        });

        const hasTags = computed(() => state.transaction.transactionLines.some((line) => line.transactionLineDetails.length > 0));

        const canAdjustHeld = computed(() => state.transaction.canAdminAdjust() && Permissions.ADMINISTRATION.canAdjustConfirmed());

        const preferredTrailerType = computed((): TrailerType => new TrailerType(state.shipment?.trailerType));

        const itemList = computed(
            (): Array<ItemTableData> => {
                const items = state.itemList.map((row) => row.item);
                return ItemTableData.BuildAdminPlanningShipmentTableData(items, state.transaction as Transaction);
            },
        );

        const itemKey = computed(
            (): ItemQtyKey => {
                if (state.transaction.status < TransactionStatus.PICKED) {
                    return 'plannedQuantity';
                }
                return 'actualQuantity';
            },
        );

        const transactionLineFields = computed(
            (): Array<BTableField<Record<string, string>>> => {
                const fields: BTableField<Record<string, string>>[] = [
                    { key: 'imageUrlThumb', label: getTitleCaseTranslation('core.domain.image') },
                    { key: 'name', label: getTitleCaseTranslation('core.domain.name') },
                    { key: 'shortName', label: getTitleCaseTranslation('core.domain.shortName') },
                    { key: 'plannedQuantity', label: getTitleCaseTranslation('core.domain.plannedQuantity') },
                    { key: 'actualQuantity', label: getTitleCaseTranslation('core.domain.actualQuantity') },
                ];

                if (state.transaction.status === TransactionStatus.DISPUTED) {
                    fields.push({ key: 'receivedQuantity', label: getTitleCaseTranslation('core.domain.receivedQuantity') });
                }

                return fields;
            },
        );

        const maxTrailerProps: MaxTrailerProps = {
            itemList,
            trailerType: preferredTrailerType,
            itemQtyKey: itemKey,
        };

        const maxTrailerComposable = useMaxTrailerCompute(maxTrailerProps);

        const {
            isDirty,
            isReceivedTransactionLineFieldsDisabled,
            goToTransactionList,
            transactionLineErrors: savingTransactionLineErrors,
            updateExistingShipment,
            validateShipmentForm: savingValidateShipmentForm,
            validationShipmentResult: savingValidationShipmentResult,
            validateTransactionForm: savingValidateTransactionForm,
            validationTransactionResult: savingValidationTransactionResult,
        } = useSupplierShipping({
            transactionListRouteName: MasterDataRouteTypes.TRANSACTION.LIST,
            state: (state as unknown) as TransactionState,
            formValidationKeyForShipments: 'scheduled-shipment',
            formValidationKeyForTransactions: 'transaction-outbound-scheduled',
        });

        const doesFromOrToLocationMatchUserLocation = computed(() => (state.transaction.fromLocationId === userLocation.id || state.transaction.toLocationId === userLocation.id));        
        const isPlannedQuantityFieldDisabled = computed((): boolean => !doesFromOrToLocationMatchUserLocation.value || state.transaction.status > TransactionStatus.ALLOCATED);
        const isActualQuantityFieldDisabled = computed((): boolean => !canAdjustHeld.value && (!doesFromOrToLocationMatchUserLocation.value || state.transaction.status >= TransactionStatus.HELD));

        const {
            moveTransactionOutOfFloor: shippingMoveTransactionOutOfFloor,
            shipNow: shippingShipNow,
            transactionLineErrors: shippingTransactionLineErrors,
            validateShipmentForm: shippingValidateShipmentForm,
            validationShipmentResult: shippingValidationShipmentResult,
            validateTransactionForm: shippingValidateTransactionForm,
            validationTransactionResult: shippingValidationTransactionResult,
        } = useSupplierShipping({
            transactionListRouteName: MasterDataRouteTypes.TRANSACTION.LIST,
            state: (state as unknown) as TransactionState,
            formValidationKeyForShipments: 'shipment',
            formValidationKeyForTransactions: 'transaction-outbound-shipping',
        });

        const transactionLineErrorsToUse = computed(() => (state.useShippingSpecificValidation ? shippingTransactionLineErrors : savingTransactionLineErrors));

        const shipmentValidationToUse = computed(() => (state.useShippingSpecificValidation ? shippingValidationShipmentResult : savingValidationShipmentResult));
        const transactionValidationToUse = computed(() => (state.useShippingSpecificValidation ? shippingValidationTransactionResult : savingValidationTransactionResult));

        const { confirm } = useDialogBox();

        async function fetchShipmentDetails(transactionId: number) {
            const response = await shipmentService.getShipmentDetailsForTransaction(transactionId);
            if (response.success && response.shipmentDetails) {
                state.shipment = response.shipmentDetails;
                state.shipment.fromLocation = props.modelValue?.fromLocation;
            }
        }

        async function fetchItemsById(ids: Array<number>): Promise<Array<Item>> {
            if (!ids.length) {
                return [];
            }
            return itemService.getItemsById(ids);
        }

        async function fetchRouteConfigItems() {
            state.loading = true;
            let resp = await routeConfigService.getAllItemsInRouteConfig(state.transaction.fromLocationId, state.transaction.toLocationId);
            if (resp) {
                const routeItemIds = resp.map((i) => i.id);
                const missingItemIds = state.transaction.transactionLines.map((line) => line.item.id).filter((id) => !routeItemIds.includes(id));

                if (missingItemIds.length) {
                    const additionalItems = await fetchItemsById(missingItemIds);
                    resp = resp.concat(additionalItems);
                }

                state.itemList = ItemTableData.BuildAdminPlanningShipmentTableData(resp, state.transaction as Transaction);
                if (state.transaction.id) {
                    state.itemList = state.itemList.filter((item) => item.actualQuantity != null || item.plannedQuantity != null);
                }
            }
            state.loading = false;
        }

        function initNew() {
            // we are using fromLocation on transaction and shipment in inconsistently
            // a comment on transaction fromLocation states that it should live on the shipment and then
            // moved to the transaction level when saving.
            state.transaction.fromLocation = userLocation;
            state.shipment.fromLocation = userLocation;
            state.transaction.shipDate = nonWorkDayService.getNextWorkingDay(new Date());
            state.originalShipmentStringified = JSON.stringify(state.shipment);
        }

        async function initExisting() {
            state.loading = true;
            state.transaction = props.modelValue;
            await fetchShipmentDetails(state.transaction.id);
            await fetchRouteConfigItems();
            state.shipment.transactions.push(state.transaction);
            state.originalShipmentStringified = JSON.stringify(state.shipment);
            state.loading = false;
        }

        onBeforeMount(async () => {
            if (props.modelValue.id) {
                await initExisting();
            } else {
                initNew();
            }
        });

        const canShipNow = computed((): boolean => state.transaction.canShipNow(userLocation));

        const itemPlannedQuantityChange = (item: Item, qty: number) => {
            state.transaction.setPlannedItemQty(item, qty);
        };

        const itemActualQuantityChange = (item: Item, qty: number) => {
            state.transaction.setActualItemQty(item, qty);
        };

        const itemReceivedQuantityChange = (item: Item, qty: number, unitLoadParentId?: number) => {
            state.transaction.setReceivedQuantity(item, qty, unitLoadParentId);
        };

        function showItemSearch() {
            state.showItemSearch = true;
        }

        async function addItem(data: { item: Item; quantity: number }) {
            const itemAlreadyAdded = state.transaction.transactionLines.find((line) => line.item.id === data.item.id);
            if (!itemAlreadyAdded) {
                const newRow = new ItemTableData(data.item);
                state.itemList.push(newRow);

                state.transaction.addEmptyLine(data.item);
            } else {
                notification.showError(getTranslation('core.validation.itemIsAlreadyAdded', data.item.name));
            }
            state.showItemSearch = false;
        }

        const showAddContainer = computed(() => {
            const hasNotShipped = !state.transaction.hasShipped;
            const isRouteLoaded = state.transaction.toLocationId && state.transaction.fromLocationId;
            const isStillEditable = state.transaction.status >= TransactionStatus.PLANNED && state.transaction.status < TransactionStatus.HELD;
            return hasNotShipped && isRouteLoaded && isStillEditable;
        });

        async function timeslotHasAvailability(): Promise<boolean> {
            if (state.shipment.scheduledDockTimeSlot) {
                const timeSlot = new Date(
                    state.transaction.plannedDepartureDate.getFullYear(),
                    state.transaction.plannedDepartureDate.getMonth(),
                    state.transaction.plannedDepartureDate.getDate(),
                    state.shipment.scheduledDockTimeSlot.getHours(),
                    state.shipment.scheduledDockTimeSlot.getMinutes(),
                );

                const existingReservations = await reservationService.getReservationsInTimeslotByServiceCenter(state.shipment.fromLocation.id, timeSlot);

                if (existingReservations.length >= (state.shipment.fromLocation.maxLoadPerTimeslot ?? 3)) {
                    let hours = state.shipment.scheduledDockTimeSlot.getHours();
                    const ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12 || 12;

                    const formattedHours = `${hours}:${state.shipment.scheduledDockTimeSlot.getMinutes().toString().padStart(2, '0')} ${ampm}`;

                    const result = await confirm({
                        title: getTitleCaseTranslation('core.common.confirmReservation'),
                        message: getTranslation('core.validation.shippingPlannerMaxLoadsExceeded', formattedHours),
                        vHtml: true,
                    });

                    if (!result) {
                        return false;
                    }
                }
            }
            return true;
        }

        async function saveNewShipment() {
            if (savingValidateShipmentForm) {
                savingValidateShipmentForm(state.shipment as Shipment);
            }

            if (savingValidateTransactionForm) {
                savingValidateTransactionForm(state.transaction as Transaction);
            }

            if (savingValidationShipmentResult?.isValid && savingValidationTransactionResult?.isValid) {
                state.saving = true;

                state.shipment.clearTransactions();
                state.shipment.addTransaction(state.transaction as Transaction);

                const response = await shipmentService.saveNewScheduledShipment(state.shipment as Shipment);
                if (response) {
                    state.wasNewShipment = true;
                    goToTransactionList();
                }

                state.saving = false;
            }
        }

        async function moveTransactionOutOfFloor(moveAction: () => Promise<void>) {
            state.useShippingSpecificValidation = true;

            if (!state.transaction.isExisting) {
                state.transaction.transferExistingItemQtyFromActualToPlanned();
            }

            if (shippingValidateShipmentForm) {
                shippingValidateShipmentForm(state.shipment as Shipment);
            }

            if (shippingValidateTransactionForm) {
                shippingValidateTransactionForm(state.transaction as Transaction);
            }

            if (shippingValidationShipmentResult?.isValid && shippingValidationTransactionResult?.isValid) {
                if (await maxTrailerComposable.confirmOverCapacity()) {
                    await moveAction();
                }
            }
        }

        async function moveToYard() {
            await moveTransactionOutOfFloor(() => shippingMoveTransactionOutOfFloor(async (shipmentId: number) => {
                const result = await shipmentService.moveTransactionsToHeld(shipmentId);
                return result;
            }));
        }

        async function submit() {
            state.useShippingSpecificValidation = false;
            if (await maxTrailerComposable.confirmOverCapacity()) {
                if (await timeslotHasAvailability()) {
                    if (state.transaction.isExisting) {
                        await updateExistingShipment();
                    } else {
                        await saveNewShipment();
                    }
                }
            }
        }

        async function ship() {
            await moveTransactionOutOfFloor(() => shippingShipNow());
        }

        async function onLocationChange() {
            if (!state.transaction.canAdminAdjust()) {
                state.isLocationChanged = true;
                if (state.transaction.fromLocationId && state.transaction.toLocationId) {
                    await fetchRouteConfigItems();
                } else {
                    state.itemList = [];
                    state.transaction.clearTransactionLines();
                    state.loading = true;
                    nextTick(() => {
                        state.loading = false;
                    });
                }
            }

            if (state.transaction.toLocation && state.transaction.toLocation.billToLocations.length === 0) {
                state.transaction.billToId = JSON.parse(JSON.stringify(state.transaction.toLocation)).id;
            }
        }

        async function confirmLeave() {
            if (isDirty.value && !state.wasNewShipment) {
                return confirm({
                    title: getTitleCaseTranslation('core.common.areYouSure'),
                    message: getTranslation('core.common.allUnsavedDataWillBeLostWhenYouLeaveThisPage'),
                });
            }
            return true;
        }

        onBeforeRouteLeave(async () => confirmLeave());

        async function goBack() {
            router.back();
        }

        const navbarTitle = computed((): string => {
            if (state.transaction.id) {
                return getTranslation('core.common.transactionNumberTitle', state.transaction.id, Formatter.GetFriendlyValue(state.transaction.status));
            }
            return getTitleCaseTranslation('core.button.addNewShipment');
        });

        async function openHistory() {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.TRANSACTION, entityId: props.modelValue!.id },
            });
        }

        async function openNotes() {
            state.notes = state.shipment.deliveryNotes ?? '';
            state.showNotes = true;
        }

        async function resetNotes() {
            state.notes = state.shipment.deliveryNotes ?? '';
            state.showNotes = false;
        }

        async function saveNotes() {
            if (!state.shipment.id) {
                state.showNotes = false;
                return;
            }

            const response = await shipmentService.updateShipmentNotes(state.shipment.id, state.notes);
            if (response) {
                state.shipment.deliveryNotes = state.notes;
            }
            state.showNotes = false;
        }

        const trackedItemLineFields = computed(
            (): Array<BTableField<TagTableData>> => {
                const fields: BTableField<TagTableData>[] = [
                    { key: 'item', label: getTitleCaseTranslation('core.domain.item'), ignoreSort: false },
                    { key: 'barcode', label: getTitleCaseTranslation('core.domain.barcode'), ignoreSort: true },
                    { key: 'productionPlant', label: getTitleCaseTranslation('core.domain.productionPlant'), ignoreSort: true },
                    { key: 'productionDate', label: getTitleCaseTranslation('core.domain.productionDate'), ignoreSort: true },
                ];

                return fields;
            },
        );

        const trackedItemLines = computed(
            (): Array<TagTableData | TagTableData[]> => {
                const trackedItems: Array<TagTableData | TagTableData[]> = [];
                state.transaction.transactionLines.forEach((line) => {
                    if (line.trackedItemList.length > 0) {
                        // customerItemNumber is nullable so we don't want a hanging hyphen if we're missing that property
                        const itemName = line.item.customerItemNumber ? `${line.item.name} - ${line.item.customerItemNumber}` : line.item.name;
                        trackedItems.push({
                            item: itemName,
                            barcode: undefined,
                            productionPlant: undefined,
                            productionDate: undefined,
                        });
                        trackedItems.push(line.trackedItemList.map((trackedItem) => ({
                            item: undefined,
                            barcode: trackedItem.barcode,
                            productionPlant: trackedItem.productionPlant,
                            productionDate: trackedItem.productionDate ? new Date(trackedItem.productionDate) : undefined,
                        })));
                    }
                });
                return trackedItems;
            },
        );

        return {
            state,
            fetchRouteConfigItems,
            submit,
            isDirty,
            itemPlannedQuantityChange,
            itemActualQuantityChange,
            goBack,
            goToTransactionList,
            openHistory,
            openNotes,
            saveNotes,
            resetNotes,
            userLocation,
            doesFromOrToLocationMatchUserLocation,
            isReceivedTransactionLineFieldsDisabled,
            isActualQuantityFieldDisabled,
            isPlannedQuantityFieldDisabled,
            transactionLineErrorsToUse,
            shipmentValidationToUse,
            transactionValidationToUse,
            itemReceivedQuantityChange,
            onLocationChange,
            maxTrailerComposable,
            transactionLineFields,
            TransactionStatus,
            canShipNow,
            ship,
            navbarTitle,
            showAddContainer,
            showItemSearch,
            addItem,
            canAdjustHeld,
            getTitleCaseTranslation,
            getTranslation,
            ItemType,
            moveToYard,
            hasTags,
            trackedItemLines,
            trackedItemLineFields,
            formatDateUI,
        };
    },
});

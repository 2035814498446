
import {
    computed, defineComponent, onMounted, PropType, reactive,
} from 'vue';
import DateInput from '@/components/inputs/DateInput.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import coreStore from '@/store/CoreStore';
import Location from '@/domain/Location';
import LocationService from '@/services/LocationService';
import Transaction from '@/domain/Transaction';
import { TransactionStatus } from '@/domain/TransactionStatus';
import Shipment from '@/domain/Shipment';
import Carrier from '@/domain/Carrier';
import CarrierService from '@/modules/master-data/services/CarrierService';
import TrailerType from '@/domain/TrailerType';
import TrailerTypeService from '@/services/TrailerTypeService';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import { emptyValidationResult } from '@/validation/useValidator';
import RouteConfigService from '@/services/RouteConfigService';
import LocationRouteConfig from '@/domain/LocationRouteConfig';
import { ValidationResult } from '@/validation/types';
import NonWorkDayService from '@/services/NonWorkDayService';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BForm from '@/components/bootstrap-library/BForm.vue';
import DockService from '@/services/DockService';
import Dock from '@/domain/Dock';
import BCol from '@/components/bootstrap-library/BCol.vue';
import InventoryCategory from '@/domain/InventoryCategory';
import Thumbnail from '@/components/Thumbnail.vue';
import ButtonUploadImage from '@/components/buttons/ButtonUploadImage.vue';
import { UploadImageEvent } from '@/domain/ImageUpload';
import Timepicker from '@/components/Timepicker.vue';
import useStringFormatter from '@/composable/useStringFormatter';
import { useNotification } from '@/composable/useNotifications';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

type State = {
    shipToLocations: Array<Location>;
    carriers: Array<Carrier>;
    trailerTypes: Array<TrailerType>;
    loading: boolean;
    toLocationsLoading: boolean;
    fromLocationsLoading: boolean;
    docksLoading: boolean;
    deliveryDate: Date | null;
    shipFromLocations: Array<Location>;
    docks: Array<Dock>;
    fromInventoryCategorySelectionKey: number;
    inventoryCategories: Array<InventoryCategory>;
};

export default defineComponent({
    name: 'master-data-supplier-shipping-head',
    components: {
        ButtonUploadImage,
        Thumbnail,
        BCol,
        BForm,
        BRow,
        DropdownAutocompleteSingleSelect,
        TextInput,
        DateInput,
        Timepicker,
    },
    props: {
        isLocationChanged: {
            type: Boolean,
            default: false,
        },
        transaction: {
            type: Transaction,
            required: true,
        },
        shipment: {
            type: Shipment,
            required: true,
        },
        disputing: {
            type: Boolean,
            required: false,
        },
        shipmentValidationResult: {
            type: Object as PropType<ValidationResult<Shipment>>,
            default: () => emptyValidationResult,
        },
        transactionValidationResult: {
            type: Object as PropType<ValidationResult<Transaction>>,
            default: () => emptyValidationResult,
        },
        canAdjustConfirmed: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['locationChange'],
    setup(props, context) {
        const {
            profileStore, timeSetStore, configStore, adminSettingsStore,
        } = coreStore.getInstance();
        const { userLocation } = profileStore;

        const locationService = new LocationService();
        const carrierService = new CarrierService();
        const trailerService = new TrailerTypeService();
        const routeConfigService = new RouteConfigService();
        const nonWorkDayService = new NonWorkDayService(timeSetStore.allTimeSets);
        const dockService = new DockService();

        const { confirm } = useDialogBox();
        const { titleCase } = useStringFormatter();

        const state = reactive<State>({
            shipToLocations: [],
            carriers: [],
            trailerTypes: [],
            loading: false,
            toLocationsLoading: false,
            fromLocationsLoading: false,
            docksLoading: false,
            deliveryDate: null,
            shipFromLocations: [],
            docks: [],
            fromInventoryCategorySelectionKey: 0,
            inventoryCategories: [],
        });

        const isFromFieldEnabled = computed(
            () => props.transaction.canEdit(userLocation)
                && (props.transaction.status === TransactionStatus.ORDERED || (props.transaction.status === TransactionStatus.PLANNED && props.transaction.fromLocationId === 0)),
        );

        const isBillToFieldEnabled = computed(
            () => ((props.canAdjustConfirmed || props.transaction.canAdminEditLocations(userLocation).to || props.transaction.fromLocation.id === 0)
                && (props.transaction.toLocation && !(props.transaction.toLocation.billToLocations.length === 0)) || !(props.transaction.fromLocation.billToLocations.length === 0)),
        );

        const billToField = computed(
            () => {
                if (props.transaction.toLocation) {
                    if (props.transaction.billToId && props.transaction.toLocation.billToLocations.length > 0) {
                        return props.transaction.toLocation.billToLocations.find((billToLocation) => billToLocation.id === props.transaction.billToId)?.name ?? '';
                    }
                    if (props.transaction.toLocation.billToLocations.length === 0) {
                        return props.transaction.toLocation.name;
                    }
                }
                return '';
            },
        );
        let routes: Array<LocationRouteConfig>;
        async function getAllRoutesForFromLocation(): Promise<LocationRouteConfig[]> {
            if (!routes) {
                routes = await routeConfigService.getRouteConfigsFromLocation(props.transaction.fromLocationId);
            }
            return routes;
        }

        async function getCarrier(fromLocationId:number, toLocationId:number): Promise<Carrier | undefined> {
            const allRoutes = await getAllRoutesForFromLocation();
            const selectedRoute = allRoutes.find((route) => route.fromLocation.id === fromLocationId && route.toLocation.id === toLocationId);
            return state.carriers.find((carrier) => carrier.id === selectedRoute?.defaultCarrierId);
        }

        async function adjustDeliveryDate(hasOverridden:boolean) {
            if (props.isLocationChanged || hasOverridden) {
                const allRoutes = await routeConfigService.getRouteConfigsFromLocation(props.transaction.fromLocationId);
                const transitTime = allRoutes.find((x) => x.toLocation.id === props.transaction.toLocationId)?.transitTime || 0;
                if (props.transaction?.shipDate) {
                    const millisecs = props.transaction.shipDate.getTime() + transitTime;
                    state.deliveryDate = nonWorkDayService.getNextWorkingDay(new Date(millisecs), props.transaction.toLocation.nonWorkingTimeSetId);
                }
            }
            else if (props.transaction.plannedDeliveryDate) {
                state.deliveryDate = props.transaction.plannedDeliveryDate;
            }
            else {
                state.deliveryDate = props.transaction.shipDate;
            }
        }

        if (props.transaction.shipDate) {
            props.transaction.shipDate = nonWorkDayService.getNextWorkingDay(props.transaction.shipDate, props.transaction.fromLocation.nonWorkingTimeSetId);
        }

        if (props.transaction.fromLocation.name) {
            state.shipFromLocations.push(props.transaction.fromLocation);
            getReceivingLocations(props.transaction.fromLocation);
        } else {        
            state.shipFromLocations.push(profileStore.userLocation);
            getReceivingLocations(profileStore.userLocation);

        }

        const isFieldDisabled = computed(() => !props.transaction.canAdminEdit(profileStore.userLocation));

        const isTrailerFieldsDisabled = computed(() => isFieldDisabled.value || !props.shipment.carrier);

        const isShipFromInventoryCategoriesDisabled = computed(() => !props.canAdjustConfirmed && (isFieldDisabled.value || !props.transaction.toLocation));

        const {
            newShipDateMin, newShipDateMax, newShipDateDisabled, adjustConfirmedShipDateMin, adjustConfirmedShipDateDisabled,
        } = nonWorkDayService.getShipDateCalendarDataBetweenDates(
            new Date(),
            Math.abs(adminSettingsStore.shipDateRangeStart),
            new Date(),
            adminSettingsStore.shipDateRangeEnd,
            props.transaction.fromLocation.nonWorkingTimeSetId,
            );

        async function getShippingDocks(locationId: number) {
            state.docksLoading = true;
            state.docks = await dockService.getInServiceShippingDocksByLocationId(locationId);
            if (state.docks.length === 1 && !disableDock.value) {
                props.shipment.dock = state.docks[0];
            }         
            state.docksLoading = false;           
        }

        async function getReceivingDocks(locationId: number) {
            state.docksLoading = true;
            state.docks = await dockService.getInServiceReceivingDocksByLocationId(locationId);
            if (state.docks.length === 1 && !disableDock.value) {
                props.shipment.dock = state.docks[0];
            }
            state.docksLoading = false;           
        }

        async function getShipToLocations(selection: Location) {
            state.toLocationsLoading = true;
            state.shipToLocations = [];
            if (selection) {
                const result = await locationService.getCanShipToLocations(selection.id);
                if (result.success) {
                    if (props.canAdjustConfirmed) {
                        const routesLookup = new Map((await getAllRoutesForFromLocation()).map((routeConfig) => [routeConfig.toLocationId, routeConfig]));
                        const transactionLineItems = props.transaction.transactionLines.map((line) => line.item);

                        state.shipToLocations = result.locations.filter((toLocation) => {
                            const routesItems = routesLookup.get(toLocation.id)?.items;

                            if (routesItems) {
                                const routeItemsIdSet = new Set(routesItems.map((routeItem) => routeItem.id));

                                return transactionLineItems.every((item) => routeItemsIdSet.has(item.id));
                            }

                            return false;
                        });
                    } else {
                        state.shipToLocations = result.locations;
                    }
                }
                state.toLocationsLoading = false;
            }
        }

        async function getReceivingLocations(selection: Location) { 
            state.fromLocationsLoading = true;
            if (selection) {
                const result = await locationService.getCanReceiveFromLocations(selection.id);
                if (result) {
                    result.map(x => state.shipFromLocations.push(x));
                }
            }  
            state.fromLocationsLoading = false;
        }

        async function getShipFromInventoryCategories(selection: Location) {
            state.inventoryCategories = [];
            const response = await routeConfigService.getAllShipFromInventoryCategoriesInRouteConfig(props.transaction.fromLocationId, selection.id);
            state.inventoryCategories = response;
            if (state.inventoryCategories.length === 1) {
                props.transaction.inventoryCategory = state.inventoryCategories[0];
            }
        }    

        const disableDock = computed((): boolean => {
            if (!props.transaction.canAdminEditDockAndTimeslot(userLocation)) return true;
            if (props.shipment.carrierId === 0) return true;
            return false;
        });

        function selectTrailerType(trailerType: TrailerType | undefined) {
            props.shipment.trailerType = trailerType;
        }

        async function trySetPreferredTrailerType() {
            state.loading = true;
            let trailerType: TrailerType | undefined;
            if (props.transaction.toLocation?.id && props.shipment.carrier?.id) {
                trailerType = (await trailerService.getPreferredTrailerTypeForRoute(props.transaction.fromLocation.id, props.transaction.toLocation.id)) ?? configStore.preferredTrailerType;
            }
            selectTrailerType(trailerType);
            state.loading = false;
        }

        onMounted(async () => {
            state.loading = true;

            const carriersResponse = await carrierService.getAllCarriers();

            if (carriersResponse.success) {
                state.carriers = carriersResponse.carriers;
                const carrier = await getCarrier(props.transaction.fromLocationId, props.transaction.toLocationId);
                if(!props.shipment.carrier){
                    props.shipment.carrier = carrier;
                    props.shipment.carrierId = props.shipment.carrier?.id ?? undefined;
                }
                await trySetPreferredTrailerType();
            }

            const trailerTypesResponse = await trailerService.getAllTrailerTypes();
            if (trailerTypesResponse.success) {
                state.trailerTypes = trailerTypesResponse.trailerTypes;
            }

            if (userLocation.id === props.transaction.toLocation.id) {
                getReceivingDocks(props.transaction.toLocation.id);
            } else {
                getShippingDocks(props.transaction.fromLocation.id);
            }
            if (props.transaction.fromLocation) {
                await getShipToLocations(props.transaction.fromLocation);
                if (props.transaction.toLocation) {
                    await getShipFromInventoryCategories(props.transaction.toLocation);
                }
            }
            adjustDeliveryDate(false);
            state.loading = false;
        });

        function selectShipFromInventoryCategory(inventoryCategory: InventoryCategory | undefined) {
            props.transaction.inventoryCategory = inventoryCategory;
            state.fromInventoryCategorySelectionKey++;
        }

        async function trySetShipFromInventoryCategory() {
            state.loading = true;
            let inventoryCategory = new InventoryCategory();
            if (props.transaction.toLocation?.id) {
                inventoryCategory = props.transaction.inventoryCategory ?? new InventoryCategory();
            }
            selectShipFromInventoryCategory(inventoryCategory);
            state.loading = false;
        }

        async function selectCarrier(carrier: Carrier | null) {
            if (carrier) {
                props.shipment.carrierId = carrier.id;
                props.shipment.carrier = carrier;
                await trySetPreferredTrailerType();
            } else {
                props.shipment.carrierId = undefined;
                props.shipment.carrier = undefined;
            }
        }

        function findLocationsByName(searchString: string) {
            const lowerCasedSearch = searchString.toLowerCase();
            return profileStore.locations.filter((location) => location.name.toLowerCase().includes(lowerCasedSearch) || location.shortName.toLowerCase().includes(lowerCasedSearch));
        }

        async function selectToLocation(loc: Location) {
            if (loc) {
                props.transaction.toLocation = loc;
                await trySetPreferredTrailerType();
                await getShipFromInventoryCategories(loc);
                await trySetShipFromInventoryCategory();
                context.emit('locationChange', props.transaction.fromLocation.id, props.transaction.toLocation.id);
            }
        }

        async function selectBillTo(loc: Location) {
            if (loc && loc.id > 0) {
                props.transaction.billToId = loc.id;
            }
        }

        async function selectFromLocation(loc: Location) {
            if (loc) {
                if (loc.id === profileStore.userLocation.id) {                    
                    getShipToLocations(loc);
                    props.transaction.fromLocation = loc;
                    getShippingDocks(loc.id);

                } else {                    
                    state.shipToLocations = [];
                    state.shipToLocations.push(profileStore.userLocation);  
                    props.transaction.toLocation = profileStore.userLocation;
                    props.transaction.fromLocation = loc;                    
                    await selectToLocation(profileStore.userLocation);
                    getReceivingDocks(profileStore.userLocation.id);
                }                 

                const allFromRoutes = await getAllRoutesForFromLocation();                
                const transitTime = allFromRoutes.find((x) => x.toLocation.id === props.transaction.toLocationId)?.transitTime || 0;
                props.transaction.shipDate = new Date(props.transaction.dateRequired.getTime() - transitTime);
            }
            context.emit('locationChange', props.transaction.fromLocation.id, props.transaction.toLocation.id);
        }

        function clearToLocation() {
            props.transaction.toLocation = new Location();
            props.transaction.billToId = undefined;
            trySetPreferredTrailerType();
            trySetShipFromInventoryCategory();
            context.emit('locationChange');
        }

        function clearBillTo() {
            props.transaction.billToId = undefined;
        }

        function updateDock(dock: Dock | null) {
            if (dock) {
                props.shipment.dock = dock;
            } else {
                props.shipment.dock = undefined;
                props.shipment.scheduledDockTimeSlot = undefined;
            }
        }

        const disableDockTimeSlot = computed((): boolean => {
            if (!props.transaction.canAdminEditDockAndTimeslot(userLocation)) return true;
            return false;
        });

        async function beforeClearCarrier(): Promise<boolean> {
            props.shipment.carrierId = undefined;
            props.shipment.carrier = undefined;
            props.shipment.trailerType = undefined;
            props.shipment.trailer = '';
            props.shipment.lot = '';
            updateDock(null);
            return true;
        }

        async function beforeClearToLocation(): Promise<boolean> {
            const response = props.transaction.canAdminAdjust() || await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.clearLocationWarning'),
            });

            if (response) {
                clearToLocation();

                return true;
            }

            return false;
        }

        async function beforeClearFromLocation() {
            props.transaction.fromLocation = new Location();
            state.docks=[];
            props.shipment.dock = undefined;
            if (props.transaction.toLocation.id) clearToLocation();
            context.emit('locationChange');
        }

        async function beforeClearTrailerType(): Promise<boolean> {
            props.shipment.trailerType = undefined;
            return true;
        }

        async function onUploadDisputeImage(value: UploadImageEvent) {
            const added = props.transaction.addDisputeImage(value);
            if (!added) {
                useNotification().showError(getTranslation('core.common.imageAlreadyAssociatedWithTransaction'));
            }
        }

        function updateDeliveredDate(value: Date) {
            if (props.transaction.status >= TransactionStatus.DELIVERY_CONFIRMED) {
                props.transaction.arrivedAt = value;
            } else {
                props.transaction.plannedDeliveryDate = value;
            }
        }

        function updateShipDate(value: Date) {
            props.transaction.shipDate = value;
            props.transaction.plannedDeliveryDate = value;
            adjustDeliveryDate(true);
        }

        return {
            findLocationsByName,
            getShipToLocations,
            getShipFromInventoryCategories,
            state,
            selectToLocation,
            beforeClearCarrier,
            beforeClearToLocation,
            beforeClearTrailerType,
            selectCarrier,
            selectTrailerType,
            selectShipFromInventoryCategory,
            TransactionStatus,
            isFieldDisabled,
            isTrailerFieldsDisabled,
            isShipFromInventoryCategoriesDisabled,
            newShipDateMin,
            newShipDateMax,
            newShipDateDisabled,
            isFromFieldEnabled,
            selectFromLocation,
            beforeClearFromLocation,
            updateDock,
            disableDockTimeSlot,
            disableDock,
            userLocation,
            titleCase,
            getTitleCaseTranslation,
            getTranslation,
            onUploadDisputeImage,
            adjustConfirmedShipDateMin,
            adjustConfirmedShipDateDisabled,
            updateDeliveredDate,
            updateShipDate,
            selectBillTo,
            billToField,
            clearBillTo,
            isBillToFieldEnabled,
        };
    },
});
